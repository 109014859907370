<template>
  <div class="yxdongtai-container">
    <navigation @searchDondtai="searchDondtai"></navigation>
    <el-breadcrumb style="background-color: #fff;padding: 30px 0 30px 30px;border-bottom: 2px solid #2C9FE8" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><span style="color: #767676;cursor: pointer;" @click="$router.push({name: 'home'})">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="color: #00BCEA;">研修动态</span></el-breadcrumb-item>
    </el-breadcrumb>
    <div style="padding: 0 20px;">
      <div class="dongtai-item" @click="toDetail(item)" v-for="(item, index) in tableData" :key="index">
        <div class="title">{{ item.newsTitle }}</div>
        <div class="time">{{ item.createTime }}</div>
      </div>
      <div style="width: 100%; text-align: center; padding: 15px 0">
        <el-pagination
                layout="prev, pager, next"
                @current-change="pageChange"
                :page-size="page.pageSize"
                :current-page="page.pageNum"
                :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import navigation from '@/components/navigation'
  export default {
    name: "index",
    components: {
      navigation,
    },
    data() {
      return {
        page: {
          pageSize: 20,
          pageNum: 1,
          total: 0
        },
        newsTitle: '',
        tableData: []
      }
    },
    mounted() {
      this.getStudyNews()
    },
    methods: {
      pageChange(pageNum) {
        this.page.pageNum = pageNum
        this.getStudyNews()
      },
      searchDondtai(e) {
        this.newsTitle = e
        this.getStudyNews()
      },
      getStudyNews() {
        let { pageSize, pageNum } = this.page
        this.$api.home.getStudyNews({
          newsTitle: this.newsTitle,
          offset: (parseInt(pageNum) - 1) * pageSize,
          limit: pageSize
        }).then(res => {
          const { code, result } = res;
          if (code == 200) {
            this.tableData = result.rows || []
            this.page.total = result.total || []
          }
        })
      },
      toDetail(item) {
        this.$router.push({
          name: 'yxdongtaiDetail',
          params: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.yxdongtai-container {
  width: 1200px;
  background-color: #fff;
  margin: auto auto 30px;
  .dongtai-item:hover {
    background-color: #f7f7f7;
  }
  .dongtai-item {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    .title {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding: 0 20px;
    }
    .time {
      width: 200px;
    }
  }
}
</style>